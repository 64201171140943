import http from "@/services/http";

export const editIntervention = (id, data) => http.put(`/interventions/${id}`, data);

export const editInterventionOffline = (id, data) => http.put(`/interventions/${id}/offline`, data);

export const signBl = (id, data) => http.put(`/interventions/${id}/sign-bl`, data);

/**
 *
 * @param {String} interventionId
 */
export const sendInterventionToOptititme = interventionId => http.post(`/intervention/${interventionId}/send-to-optitime`);
