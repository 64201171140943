/**
 * This mapping prevents from using file types id
 * directly for business logic
 */
module.exports = {
  cpo: "0101",
  cpi: "0102",
  cpr: "0105",
  phoneMonitoringReport: "0006",
  insulineObs: "0005",
  bl: "0106",
  examen: "0008",
  pairingConsent: "0009",
  ordonnance: "0010",
  patientSignature: "0011",
  administrative: "0012",
  updateInterventionPdf: "0013",
  mutuelle: "0014",
  vitale: "0015",
  prescription: "0016",
  courrier: "0017",
  attestation: "0018",
  grille: "0019",
  rib: "0020",
  interventionQuestionnaire: "interventionQuestionnaire",
  ficheMaintenanceContinue: "ficheMaintenanceContinue",
  legacyNote: "legacyNote",
  other: "9999"
};
